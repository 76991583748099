import './App.css';
import { TitleComponet } from './components/title-component/title-component';
import { BasicInfoComponent } from './components/basic-info/basic-info';
import { Taskbar } from './components/taskbar/taskbar';
import { Websites } from './components/websites/websites';
// import { Hobbies } from './components/hobbies/hobbies';

function App() {
  return (
    <div className='main-container bg-base-100' >
      <Taskbar></Taskbar>
      <div className='section-1'>
        <TitleComponet title='Nick Gardner' subTitle='Web Developer'></TitleComponet>
        <BasicInfoComponent></BasicInfoComponent>
      </div>
      {/* <div className='experience-container w-50'>
        <Experience title='Experience'></Experience>
      </div> */}
      <div className='websites-container w-full'>
        <Websites></Websites>
      </div>
      {/* <div className='websites-container w-full'>
        <Hobbies></Hobbies>
      </div> */}
      <div className='empty-space'></div>
    </div>
  );
}

export default App;
