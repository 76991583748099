import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './animations.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as swaClient from 'serverless-website-analytics-client';

const reactEnvironment = process.env.REACT_APP_ENVIRONMENT;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
swaClient.v1.analyticsPageInit({
  inBrowser: true,
  site: reactEnvironment === 'test' ? 'test.gardnernick.com' : 'gardnernick.com',
  apiUrl: 'https://analytics.gardnernick.com',
  // debug: true,
});
swaClient.v1.analyticsPageChange("/");


root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
