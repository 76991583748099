import { Modal } from '../modal/modal';
import { ProjectProps } from './contracts/project-props';
import './project.css';

export function Project(props: ProjectProps) {

    return (
        <div className='card bg-base-100 image-full w-96 shadow-xl project-card' onClick={() =>(document.getElementById(props.id) as any).showModal()}>
            <figure>
                {backgroundImage(props.logo)}
            </figure>
            <div className='card-body'>
                <h2 className='card-title project-card-title'>{props.title}</h2>
                <p>{props.description}</p>
            </div>

            <Modal id={props.id} title={props.title} mainInfo={props.description}></Modal>
        </div>
    );
}

const backgroundImage = (logo?: string) => {
    if (logo) {
        return (
            <img
            src={logo}
            alt='logo' />
        );
    }

    return (<></>);
} 